import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { t } from 'i18next'
import './HutupiaBanner.css'

const styles = {
  container: {
    // fontFamily: 'Arial, sans-serif',
    // background: 'linear-gradient(to right, #4E54C8, #8F94FB)',
    color: '#fff',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
    margin: 'auto 0',
    display: 'flex',
    justifyContent: 'center',
  },
  banner: {
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
  },
  title: {
    fontSize: '3rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  subtitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  description: {
    fontSize: '1rem',
    marginBottom: '20px',
    lineHeight: '1.5',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  button: {
    margin: '10px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
}

const HutupiaBanner = () => {
  const handleLoginClick = () => {
    // Handle login click
    window.location.href = `https://hutupia.com/login`
  }

  const handleRegisterClick = () => {
    // Handle register click
    window.location.href = `https://hutupia.com/register`
  }

  return (
    <Grid
      container
      spacing={3}
      alignItems='center'
      justify='center'
      style={styles.container}
    >
      {/* Animated background */}
      <div className='animatedBackground'>
        <div className='pulsingCircles'></div>
        <div className='rotatingTriangles'></div>
      </div>

      <Grid item xs={12} md={6} style={styles.banner}>
        <Typography variant='h1' style={styles.title} gutterBottom>
          {t('Welcome_to_Hutupia')}
        </Typography>
        <Typography variant='subtitle1' style={styles.subtitle} paragraph>
          {t('hutupia_slogan_1')}
        </Typography>
        <Typography variant='body1' style={styles.description} paragraph>
          {t('hutupia_slogan_2')}
        </Typography>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleLoginClick}
          style={styles.button}
        >
          {t('Login')}
        </Button>
        <Button
          variant='outlined'
          color='secondary'
          onClick={handleRegisterClick}
          style={styles.button}
        >
          {t('Register')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default HutupiaBanner
