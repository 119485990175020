import { Routes, Route } from 'react-router-dom'
import HutupiaTerms from './components/HutupiaTerms'
import HutupiaIntroduction from './components/HutupiaIntroduction'
import getUserCountry from './components/utils/getUserCountry'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { useEffect } from 'react';
import languageMap from './components/utils/languageMap';
import PageDirection from './components/utils/PageDirection';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'ar'],
    fallbackLng: 'en',
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'path',
        'navigator',
        'subdomain',
        'htmlTag',
      ],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translation',],
  })



function App() {
  PageDirection()
  const countryCode = getUserCountry()
  const props = { countryCode }

  useEffect(() => {
    const setLanguage = async () => {
      const countryCode = await getUserCountry();
      const language = languageMap[countryCode] || 'en';
      i18n.changeLanguage(language);
    };
    setLanguage();
  }, [])

  return (
    <Routes>
      <Route exact path='/' element={<HutupiaIntroduction {...props} />} />
      <Route exact path='/terms' element={<HutupiaTerms {...props} />} />
    </Routes>
  )
}

export default App
