import React from 'react'
import { useTranslation } from 'react-i18next'
import { Facebook, LinkedIn, Email } from '@mui/icons-material'
import { FaXTwitter } from 'react-icons/fa6'

const styles = {
  footerContainer: {
    textAlign: 'center',
    padding: '40px 20px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    backgroundColor: '#1a1a1a',
    color: '#ffffff',
    marginTop: '20px',
  },
  footerLink: {
    display: 'block',
    margin: '10px 0',
    textDecoration: 'none',
    color: '#007bff',
    fontWeight: 'bold',
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  socialIcon: {
    margin: '0 10px',
    fontSize: '30px',
    color: '#007bff',
    cursor: 'pointer',
  },
  section: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  creatorInfo: {
    fontSize: '16px',
    marginTop: '20px',
  },
  rightsInfo: {
    fontSize: '14px',
    marginTop: '10px',
    color: '#999',
  },
}

const IntroFooter = () => {
  const { t } = useTranslation()

  const openLink = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div style={styles.footerContainer}>
      <div style={styles.section}>
        <h3 style={styles.title}>{t('Quick_Links')}</h3>
        <div style={styles.linksContainer}>
          <a href='/terms' style={styles.footerLink}>
            {t('Terms_of_Use')}
          </a>
          <a href='https://library.hutupia.com' style={styles.footerLink}>
            {t('Learn_More_at_Hutupia_Library')}
          </a>
          <a href='mailto:hutupiainfo@gmail.com' style={styles.footerLink}>
            {t('Contact_Us')} (hutupiainfo@gmail.com)
          </a>
        </div>
      </div>

      <div style={styles.section}>
        <h3 style={styles.title}>{t('Follow_Us')}</h3>
        <div style={styles.socialMedia}>
          <Facebook
            style={styles.socialIcon}
            onClick={() =>
              openLink(
                'https://www.facebook.com/profile.php?id=100091735916505'
              )
            }
          />
          <FaXTwitter
            style={styles.socialIcon}
            onClick={() => openLink('https://x.com/hutupia_network')}
          />
          <LinkedIn
            style={styles.socialIcon}
            onClick={() => openLink('https://www.linkedin.com/company/hutupia')}
          />
          <Email
            style={styles.socialIcon}
            onClick={() => openLink('mailto:hutupiainfo@gmail.com')}
          />
        </div>
      </div>

      <div style={styles.section}>
        <h3 style={styles.title}>{t('About_the_Creator')}</h3>
        <p style={styles.creatorInfo}>
          {t('Hutupia_was_created_by_Mohamed_Abd_El_Nasser')}
          <br />
          <a
            href='https://www.linkedin.com/in/mohamed-abd-el-nasser-92955a211'
            style={styles.footerLink}
          >
            {t('Connect on LinkedIn')}
          </a>
        </p>
      </div>

      <div style={styles.rightsInfo}>
        &copy; 2024 {t('Hutupia')}. {t('All_rights_reserved')}.
      </div>
    </div>
  )
}

export default IntroFooter
