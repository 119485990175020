import React, { useEffect, useState } from 'react'
import CardsConfigs from './HutupiaIntroComps/CardsConfigs'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import FeatureCard from './HutupiaIntroComps/FeatureCard'
import HutupiaBanner from './HutupiaIntroComps/HutupiaBanner'
import { useTranslation } from 'react-i18next'
import IntroFooter from './HutupiaIntroComps/IntroFooter'

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  description: {
    fontSize: '18px',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
  link: {
    display: 'block',
    margin: '10px 0',
    textDecoration: 'none',
    color: '#000',
    fontWeight: 'bold',
  },
  listContainer: {
    marginBottom: '40px',
  },
  listItem: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  listItemTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  listItemDescription: {
    fontSize: '16px',
    lineHeight: '1.5',
  },
  button: {
    margin: '20px 0',
  },
  icon: {
    fontSize: '40px',
    color: '#007bff',
  },
  featureTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#343a40',
  },
  featureDescription: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#495057',
  },
  featureCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
    margin: '10px',
  },
}

const HutupiaIntroduction = () => {
  const { t } = useTranslation()

  useEffect(() => {
    const cards = document.querySelectorAll('.slide-in')
    cards.forEach((card, index) => {
      card.style.transitionDelay = `${index * 200}ms`
    })
  }, [])

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  return (
    <div style={{ overflowX: 'hidden' }}>
      {' '}
      {/* Ensure no overflow in the x-direction */}
      <HutupiaBanner />
      <div style={styles.container}>
        <h2 style={styles.title}>{t('What_Can_You_Do?')}</h2>
        <Slider {...settings}>
          {CardsConfigs().map((feature, index) => (
            <div key={index}>
              <FeatureCard feature={feature} index={index} />
            </div>
          ))}
        </Slider>
      </div>
      <IntroFooter />
    </div>
  )
}

export default HutupiaIntroduction
