import React from 'react'

const ArabicTerms = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  }

  const headerStyle = {
    color: '#007BFF',
  }

  const subHeaderStyle = {
    color: '#0056b3',
  }

  const listStyle = {
    paddingLeft: '20px',
  }

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>شروط الاستخدام</h1>
      <p>
        مرحبًا بك في هيوتيوبيا، منصة للتواصل الاجتماعي التي توفر للمستخدمين مجموعة
        من الأدوات والميزات المبتكرة المصممة لتسهيل التعاون وإدارة المشاريع. عند
        الوصول واستخدام هيوتيوبيا، فإنك توافق على الالتزام بالشروط والأحكام
        التالية. <br />
        <br /> توفر هيوتيوبيا مجموعة واسعة من الميزات التي تساعد المستخدمين على
        تحقيق أهدافهم العملية. ومن بين هذه الميزات: القدرة على التواصل عبر
        الدردشة، ومشاركة المحتوى مثل الصور والروابط والنصوص، وعرض الرسوم
        البيانية والتحليلات للحصول على رؤى حول مشاريعهم. كما يستطيع المستخدمون
        إنشاء عقود افتراضية، ونمذجة مشاريعهم بكفاءة أكبر، وتحديد متطلبات
        المشروع، وتخصيص الموارد، وإنشاء خطط مشروع مفصلة. <br />
        <br /> بالإضافة إلى ميزات إدارة المشاريع، توفر هيوتيوبيا وصولاً إلى فرص
        العمل، وقنوات التواصل، والقدرة على الاستثمار في مشاريع مختلفة. كما توفر
        المنصة استراتيجيات تسويقية ونماذج أعمال يمكن أن تكون مفيدة لنمذجة
        المشاريع والأفكار بكفاءة أكبر. ويمكن للمستخدمين مشاركة المستندات، وتفويض
        المهام، ومراقبة تقدم مشاريعهم باستخدام الهيكل التسلسلي الذي يتضمن
        المديرين والموظفين. <br />
        <br /> من بين ميزات هيوتيوبيا الأخرى هي القدرة على مشاركة الموارد بين
        المستخدمين، وإدارة الاستفسارات والاستفسارات المتعلقة بمشاريعهم، والوصول
        إلى ميزة الاستفسارات العالمية، التي تساعد المستخدمين على تحقيق والوصول
        إلى جمهور أوسع بشأن احتياجاتهم، والتغييرات الاجتماعية والعالمية. <br />
        <br /> نحن نعتقد أن هيوتيوبيا هي أداة قوية يمكن أن تساعد المستخدمين على
        الاتصال والتعاون وتحقيق أهدافهم العملية.
      </p>

      <h2 style={subHeaderStyle}>قبول الشروط</h2>
      <p>
        باستخدام هيوتيوبيا، فإنك توافق على شروط الاستخدام هذه. إذا لم توافق على
        هذه الشروط، فلا يمكنك استخدام هيوتيوبيا. يجب أن تكون على الأقل 18 عامًا
        لاستخدام هيوتيوبيا.
      </p>

      <h2 style={subHeaderStyle}>سلوك المستخدم</h2>
      <p>
        توافق على استخدام هيوتيوبيا فقط للأغراض القانونية وبطريقة لا تنتهك حقوق
        الآخرين. كمنصة للتواصل الاجتماعي التي تمكِّن المستخدمين من الاتصال
        والتواصل والتعاون، تلتزم هيوتيوبيا بتعزيز بيئة احترام ومهنية وسلوك أخلاقي.
        لا يمكنك استخدام هيوتيوبيا لنشر أو نقل أي محتوى غير قانوني أو مسيء أو مزعج
        أو ينتهك حقوق الآخرين. بالإضافة إلى ذلك، لا يمكنك استخدام هيوتيوبيا
        للمشاركة في أي نشاط ينتهك أي قوانين أو لوائح.
        <br />
        <br />
        نحن نأخذ سلامة هيوتيوبيا على محمل الجد ونتوقع من جميع مستخدمينا الالتزام
        بتلك الإرشادات. باستخدام هيوتيوبيا، تقر وتوافق على الامتثال لشروط
        الاستخدام هذه، واستخدام المنصة بطريقة مسؤولة ومحترمة.
      </p>

      <h2 style={subHeaderStyle}>تسجيل المستخدم</h2>
      <p>
        للوصول إلى بعض ميزات هيوتيوبيا، قد يكون من الضروري تسجيل حساب. عند تسجيل
        حساب، فإنك توافق على تزويدنا بمعلومات دقيقة وكاملة. أنت المسؤول الوحيد
        عن جميع الأنشطة التي تحدث على حسابك، ويجب عليك الحفاظ على أمان كلمة مرور
        حسابك. من مسؤوليتك إخطار هيوتيوبيا على الفور في حالة اكتشاف أي استخدام غير
        مصرح به لحسابك.
        <br />
        <br />
        نحن نأخذ أمان معلومات مستخدمينا على محمل الجد، ونلتزم بحماية خصوصيتك.
        عند تسجيل حساب، فإنك تقر وتوافق على الامتثال لسياسة الخصوصية الخاصة بنا،
        والتي تحدد التزامنا بحماية معلوماتك الشخصية.
      </p>

      <h2 style={subHeaderStyle}>حقوق الملكية الفكرية</h2>
      <p>
        المحتوى المتاح على هيوتيوبيا، بما في ذلك النصوص والصور والمواد الأخرى،
        محمي بموجب قوانين حقوق الملكية الفكرية والعلامات التجارية والقوانين
        الأخرى ذات الصلة. نحن نحترم حقوق الملكية الفكرية للآخرين، ونتوقع من
        مستخدمينا القيام بالشيء نفسه. لا يجوز استخدام أي من المحتوى المتاح على
        هيوتيوبيا دون الحصول على إذن من مالك المحتوى. بالإضافة إلى ذلك، لا يجوز
        تعديل أو استنساخ أو توزيع أو إنشاء أعمال مشتقة بناءً على أي من المحتوى
        المتاح على هيوتيوبيا.
        <br />
        نحن نأخذ حقوق الملكية الفكرية على محمل الجد ولن يتم تحمل أي انتهاك لتلك
        الحقوق. باستخدام هيوتيوبيا، فإنك تقر وتوافق على الامتثال لهذه الإرشادات
        واحترام حقوق الملكية الفكرية للآخرين.
      </p>

      <h2 style={subHeaderStyle}>المحتوى الذي يتم إنشاؤه من قبل المستخدمين</h2>
      <p>
        في هيوتيوبيا، ندرك أهمية المحتوى الذي يتم إنشاؤه من قبل المستخدمين والقيمة
        التي يضيفها إلى منصتنا. يمكن لمستخدمي هيوتيوبيا نشر المحتوى، بما في ذلك
        النصوص والصور والروابط. عند نشر محتوى على هيوتيوبيا، فإنك تمنحنا ترخيصًا
        غير حصري وخاليًا من العوائد لاستخدام وعرض واستنساخ وتوزيع المحتوى بأي
        شكل أو وسيلة. نحن نأخذ الثقة التي يضعها مستخدمونا فينا على محمل الجد
        ونلتزم بتحقيق أعلى معايير النزاهة والاحترام لحقوق مستخدمينا.
        <br />
        نؤمن بأن المحتوى الذي يتم إنشاؤه من قبل المستخدمين هو عنصر أساسي في
        هيوتيوبيا، ونحن نلتزم بتوفير بيئة آمنة ومأمونة لمستخدمينا لإنشاء ومشاركة
        محتواهم. باستخدام هيوتيوبيا، فإنك تقر وتوافق على منحنا ترخيصًا غير حصري
        وخاليًا من العوائد لاستخدام وعرض واستنساخ وتوزيع محتواك بأي شكل أو
        وسيلة.
      </p>

      <p>
        في هيوتيوبيا، نحن ملتزمون بتوفير بيئة آمنة ومحترمة لمستخدمينا. ولهذا
        الغرض، فإننا نحتفظ بالحق في إزالة أي محتوى ينتهك شروط الاستخدام الخاصة
        بنا أو الذي نعتبره غير مقبول. نحن نأخذ هذه الإرشادات على محمل الجد ولن
        نتهاون مع أي سلوك يضر بنزاهة منصتنا أو يؤذي مستخدمينا.
        <br />
        بالإضافة إلى ذلك، نحتفظ بالحق في إنهاء حسابات المستخدمين الذين ينتهكون
        شروط الاستخدام لدينا بشكل متكرر. نحن نعتقد أن هذا ضروري لضمان بقاء
        منصتنا مكانًا آمنًا ومأمونًا لجميع المستخدمين.
      </p>

      <h2 style={subHeaderStyle}>سياسة الخصوصية</h2>
      <p>
        في هيوتيوبيا، نحن نأخذ خصوصيتك على محمل الجد. نحن ملتزمون بحماية معلوماتك
        الشخصية وضمان استخدامها بطريقة مسؤولة وشفافة. توضح هذه السياسة سرية
        أنواع المعلومات الشخصية التي نجمعها وكيفية استخدام تلك المعلومات وكيفية
        حمايتها.
      </p>
      <div>
        <h3>المعلومات التي نجمعها</h3>
        <ul style={listStyle}>
          <li>
            معلومات الاتصال مثل الاسم وعنوان البريد الإلكتروني ورقم الهاتف
          </li>
          <li>معلومات ديموغرافية مثل العمر والجنس والموقع الجغرافي</li>
          <li>
            معلومات حول استخدامك لمنصتنا، بما في ذلك المحتوى الذي تنشئه وتشاركه،
            والأشخاص الذين تتفاعل معهم، وتكرار استخدامك ومدته.
          </li>
          <li>معلومات حول جهازك، مثل عنوان IP ونظام التشغيل ونوع المتصفح.</li>
        </ul>
        <h3>كيفية استخدامنا لمعلوماتك</h3>{' '}
        <p>نستخدم معلوماتك الشخصية للأغراض التالية:</p>{' '}
        <ul style={listStyle}>
          {' '}
          <li>لتوفير تجربة مخصصة لك على منصتنا</li>{' '}
          <li>
            {' '}
            للتواصل معك حول خدماتنا، بما في ذلك المواد التسويقية والدعائية{' '}
          </li>{' '}
          <li>لتحسين منصتنا وخدماتنا</li>{' '}
          <li>للامتثال للمتطلبات القانونية والتنظيمية</li>{' '}
        </ul>
        <p>
          قد نستخدم أيضًا معلوماتك الشخصية لأغراض أخرى يتم الإفصاح عنها لك في
          وقت الجمع، أو مع موافقتك.
        </p>
        <h3>كيفية حماية معلوماتك</h3>
        <p>
          نتخذ كل الاحتياطات لضمان أن تبقى معلوماتك الشخصية آمنة وسرية. نستخدم
          مجموعة متنوعة من التدابير الفنية والإدارية والفيزيائية لحماية معلوماتك
          من الوصول غير المصرح به أو الاستخدام أو الكشف عنه.
        </p>
        <h3>مشاركة معلوماتك</h3>
        <p>
          لا نشارك معلوماتك الشخصية مع أطراف ثالثة بدون موافقتك، إلا في حالات
          محدودة مثل:
        </p>
        <ul style={listStyle}>
          <li>للامتثال للمتطلبات القانونية والتنظيمية</li>
          <li>لحماية حقوق هيوتيوبيا، ومستخدمينا، أو الآخرين</li>
          <li>لفرض شروط الاستخدام أو اتفاقيات أخرى</li>
        </ul>
        <p>
          قد نشارك معلوماتك مع مزودي الخدمات لدينا، الذين يساعدوننا في توفير
          خدماتنا وتحسينها. هؤلاء المزودين ملزمون باتفاقيات سرية وممنوعون من
          استخدام معلوماتك لأي غرض آخر.
        </p>
        <h3>خياراتك</h3>
        <p>لديك الخيارات التالية بشأن معلوماتك الشخصية:</p>
        <ul style={listStyle}>
          <li>
            يمكنك إلغاء اشتراكك في تلقي المواد التسويقية والدعائية منا عن طريق
            اتباع التعليمات المقدمة في تلك المواد.
          </li>
          <li>
            يمكنك تحديث أو تصحيح معلوماتك الشخصية عن طريق تسجيل الدخول إلى حسابك
            وإجراء التغييرات اللازمة.
          </li>
          <li>يمكنك طلب حذف معلوماتك الشخصية عن طريق الاتصال بنا.</li>
        </ul>
        <p>
          يرجى ملاحظة أنه قد لا يكون بإمكاننا حذف معلوماتك الشخصية إذا كنا
          ملزمين بالاحتفاظ بها بموجب القانون أو لأغراض تجارية مشروعة.
        </p>
        <h3>تغييرات على هذه السياسة</h3>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. إذا قمنا بإجراء تغييرات
          جوهرية على هذه السياسة، فسنخطرك بذلك عن طريق البريد الإلكتروني أو من
          خلال نشر إشعار على منصتنا.
        </p>
      </div>

      <h2 style={subHeaderStyle}>تنصل من المسؤولية</h2>
      <p>
        توفر هيوتيوبيا على أساس "كما هي" و "حسب التوافر"، دون أي تمثيلات أو ضمانات
        من أي نوع، صريحة أو ضمنية. في حين نسعى إلى توفير منصة عالية الجودة مع
        معلومات دقيقة وموثوقة، إلا أننا لا يمكننا ضمان أن هيوتيوبيا ستكون خالية من
        الأخطاء أو العيوب أو الانقطاعات.
        <br />
        <br />
        لا يتحمل هيوتيوبيا أية مسؤولية عن أي انقطاع في استخدام هيوتيوبيا، ويشمل ذلك،
        على سبيل المثال لا الحصر، الانقطاعات الناتجة عن الصيانة أو التحديثات أو
        مشاكل تقنية أخرى، وكذلك الانقطاعات الناتجة عن أحداث خارجة عن سيطرتنا،
        مثل الكوارث الطبيعية أو الأعمال العدائية.
        <br />
        <br />
        باستخدام هيوتيوبيا، فإنك تعترف وتقبل المخاطر المرتبطة باستخدام منصة عبر
        الإنترنت وتوافق على استخدام هيوتيوبيا على مسؤوليتك الخاصة. إذا واجهت أي
        مشكلات أو لديك أي مخاوف بشأن استخدام هيوتيوبيا، فيرجى عدم التردد في
        الاتصال بنا. نحن ملتزمون بمعالجة أي مشاكل وتحسين المنصة لتوفير أفضل
        تجربة ممكنة لمستخدمينا.
      </p>

      <h2 style={subHeaderStyle}>إنهاء الخدمة</h2>
      <p>
        تحتفظ هيوتيوبيا بالحق في إنهاء وصولك إلى الموقع في أي وقت ودون إشعار مسبق
        ولأي سبب. إذا تم إنهاء حسابك، فقد تفقد الوصول إلى أي محتوى أو ميزات أو
        خدمات قمت بشرائها أو إنشائها على المنصة.
        <br />
        <br />
        كما يحق لنا أن ننهي وصولك إلى الموقع إذا اعتقدنا أنك قد انتهكت شروط
        الاستخدام الخاصة بنا أو ارتكبت أي نشاط نعتبره ضارًا أو غير مناسب.
        <br />
        <br />
        "قبل حذف حسابك، يجب عليك إتمام بعض الخطوات الهامة لضمان عدم تأثير حذف
        حسابك سلباً على مجتمع هيوتوبيا. يمكن أن تتضمن هذه الخطوات إنهاء أي مشاكل
        معلقة، وإنهاء أي عقود أو اشتراكات مع مستخدمين آخرين، وحذف أي مشاريع
        تمتلكها. يرجى الاتصال بنا إذا كان لديك أي أسئلة أو مخاوف بشأن عملية
        الإنهاء.
        <br />
        <br />
        إذا كان لديك أي أسئلة أو مخاوف بشأن إنهاء حسابك، فيرجى الاتصال بنا. نحن
        ملتزمون بحل أي مشاكل وضمان تجربة إيجابية لمستخدمينا على المنصة.
      </p>

      <h2 style={subHeaderStyle}>القانون الساري</h2>
      <p>
        يخضع هذه الشروط والأحكام للقوانين المعمول بها في السلطة القضائية التي
        تتخذ منها هيوتيوبيا مقرًا لها. ويخضع أي نزاع ينشأ عن أو يتعلق بهذه الشروط
        والأحكام للولاية القضائية الحصرية للمحاكم في تلك الولاية.
        <br />
        <br />
        نحن نسعى إلى توفير منصة شفافة وعادلة لمستخدمينا، ونحن ملتزمون بمعالجة أي
        مشاكل أو مخاوف قد تنشأ. إذا كان لديك أي أسئلة أو مخاوف بشأن هذه الشروط
        والأحكام، فيرجى عدم التردد في الاتصال بنا.
      </p>

      <h2 style={subHeaderStyle}>تغييرات على الشروط والأحكام</h2>
      <p>
        تحتفظ هيوتيوبيا بالحق في تحديث أو تعديل هذه الشروط والأحكام في أي وقت ودون
        إشعار مسبق. يشكل استمرار استخدام هيوتيوبيا بعد أي تغييرات من هذا النوع
        موافقتك على الشروط والأحكام الجديدة.
      </p>
    </div>
  )
}

export default ArabicTerms
