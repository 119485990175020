import React from 'react'

const EnglishTerms = () => {
  const containerStyle = {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    lineHeight: '1.6',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  }

  const headerStyle = {
    color: '#007BFF',
  }

  const subHeaderStyle = {
    color: '#0056b3',
  }

  const listStyle = {
    paddingLeft: '20px',
  }

  return (
    <div style={containerStyle}>
      <h1 style={headerStyle}>Terms of Use</h1>
      <p>
        Welcome to Hutupia, a social media platform that provides users with a
        range of innovative tools and features designed to facilitate
        collaboration and project management. By accessing and using Hutupia,
        you agree to be bound by the following terms of use.
      </p>
      <p>
        Hutupia offers a vast array of features to help users achieve their
        business objectives. These features include the ability to communicate
        via chat, share content such as images, links, and text, and view charts
        and analytics to gain insights into their projects. Users can also
        create virtual contracts, model their projects more efficiently, and
        define project requirements, allocate resources, and create detailed
        project plans.
      </p>
      <p>
        In addition to project management features, Hutupia offers access to job
        opportunities, networking channels, and the ability to invest in
        different projects. The platform also provides marketing strategies and
        business models that can be useful for modeling projects and ideas more
        efficiently. Users can share documents, delegate tasks, and oversee the
        progress of their projects using the hierarchical structure that
        includes managers and employees.
      </p>
      <p>
        Other Hutupia features include the ability to share resources between
        users, manage queries and inquiries related to their projects, and
        access the Global Inquiries feature, which helps users to realize and
        reach a broader audience regarding their needs, as well as social and
        global changes.
      </p>
      <p>
        We believe that Hutupia is a powerful tool that can help users to
        connect, collaborate, and achieve their business objectives.
      </p>

      <h2 style={subHeaderStyle}>Acceptance of Terms</h2>
      <p>
        By using Hutupia, you agree to these terms of use. If you do not agree
        to these terms, you may not use Hutupia. You must be at least 18 years
        old to use Hutupia.
      </p>

      <h2 style={subHeaderStyle}>User Conduct</h2>
      <p>
        You agree to use Hutupia only for lawful purposes and in a manner that
        does not infringe on the rights of others. As a social media platform
        that enables users to connect, communicate, and collaborate, Hutupia is
        committed to fostering an environment of respect, professionalism, and
        ethical behavior. You may not use Hutupia to post or transmit any
        content that is unlawful, defamatory, abusive, harassing, or otherwise
        objectionable. Additionally, you may not use Hutupia to engage in any
        activity that violates any laws or regulations.
      </p>
      <p>
        We take the integrity of Hutupia very seriously, and we expect all of
        our users to adhere to these guidelines. By using Hutupia, you
        acknowledge and agree to comply with these terms of use, and to use the
        platform in a responsible and respectful manner.
      </p>

      <h2 style={subHeaderStyle}>User Registration</h2>
      <p>
        To access certain features of Hutupia, it may be necessary to register
        an account. By registering an account, you agree to provide accurate and
        complete information. You are solely responsible for all activity that
        occurs on your account, and you must keep your account password secure.
        It is your responsibility to immediately notify Hutupia of any
        unauthorized use of your account.
      </p>
      <p>
        We take the security of our users' information very seriously and are
        committed to protecting your privacy. By registering an account, you
        acknowledge and agree to comply with our privacy policy, which outlines
        our commitment to safeguarding your personal information.
      </p>

      <h2 style={subHeaderStyle}>Intellectual Property Rights</h2>
      <p>
        The content available on Hutupia, including text, images, and other
        materials, is protected by copyright, trademark, and other intellectual
        property laws. We respect the intellectual property rights of others,
        and we expect our users to do the same. You may not use any of the
        content on Hutupia without obtaining permission from the owner of the
        content. Additionally, you may not modify, reproduce, distribute, or
        create derivative works based on any of the content available on
        Hutupia.
      </p>
      <p>
        We take intellectual property rights very seriously, and any violation
        of these rights will not be tolerated. By using Hutupia, you acknowledge
        and agree to comply with these guidelines and to respect the
        intellectual property rights of others.
      </p>

      <h2 style={subHeaderStyle}>User-Generated Content</h2>
      <p>
        At Hutupia, we understand the importance of user-generated content and
        the value it brings to our platform. Users of Hutupia may post content,
        including text, images, and links. By posting content on Hutupia, you
        grant us a non-exclusive, royalty-free license to use, display,
        reproduce, and distribute the content in any format or media. We take
        the trust our users place in us very seriously and are committed to
        upholding the highest standards of integrity and respect for our users'
        rights.
      </p>
      <p>
        We believe that user-generated content is an essential component of
        Hutupia, and we are committed to providing a safe and secure environment
        for our users to create and share their content. By using Hutupia, you
        acknowledge and agree to grant us a non-exclusive, royalty-free license
        to use, display, reproduce, and distribute your content in any format or
        media.
      </p>
      <p>
        At Hutupia, we are committed to providing a safe and respectful
        environment for our users. To that end, we reserve the right to remove
        any content that violates our terms of use or that we deem
        objectionable. We take these guidelines very seriously, and we will not
        tolerate any behavior that undermines the integrity of our platform or
        harms our users.
      </p>
      <p>
        In addition, we reserve the right to terminate the accounts of users who
        repeatedly violate our terms of use. We believe that this is necessary
        to ensure that our platform remains a safe and secure place for all
        users.
      </p>

      <h2 style={subHeaderStyle}>Privacy Policy</h2>
      <p>
        At Hutupia, we take your privacy very seriously. We are committed to
        protecting your personal information and ensuring that it is used in a
        responsible and transparent manner. This privacy policy outlines the
        types of personal information we collect, how we use that information,
        and how we safeguard that information.
      </p>
      <div>
        <h3>Information We Collect</h3>
        <ul style={listStyle}>
          <li>
            Contact information, such as your name, email address, and phone
            number
          </li>
          <li>
            Demographic information, such as your age, gender, and location
          </li>
          <li>
            Information about your use of our platform, including the content
            you create and share, the people you interact with, and the
            frequency and duration of your use
          </li>
          <li>
            Information about your device, such as your IP address, operating
            system, and browser type
          </li>
        </ul>

        <h3>How We Use Your Information</h3>
        <p>We use your personal information for the following purposes:</p>
        <ul style={listStyle}>
          <li>To provide you with a personalized experience on our platform</li>
          <li>
            To communicate with you about our services, including marketing and
            promotional materials
          </li>
          <li>To improve our platform and services</li>
          <li>To comply with legal and regulatory requirements</li>
        </ul>

        <p>
          We may also use your personal information for other purposes that are
          disclosed to you at the time of collection, or with your consent.
        </p>

        <h3>How We Safeguard Your Information</h3>
        <p>
          We take every precaution to ensure that your personal information is
          kept secure and confidential. We use a variety of physical, technical,
          and administrative safeguards to protect your information from
          unauthorized access, use, or disclosure.
        </p>

        <h3>Sharing Your Information</h3>
        <p>
          We do not share your personal information with third parties without
          your consent, except in limited circumstances, such as:
        </p>
        <ul style={listStyle}>
          <li>To comply with legal and regulatory requirements</li>
          <li>
            To protect the rights, property, or safety of Hutupia, our users, or
            others
          </li>
          <li>To enforce our terms of use or other agreements</li>
        </ul>

        <p>
          We may also share your information with our service providers, who
          assist us in providing and improving our services. These service
          providers are bound by confidentiality agreements and are prohibited
          from using your information for any other purpose.
        </p>

        <h3>Your Choices</h3>
        <p>
          You have the following choices regarding your personal information:
        </p>
        <ul style={listStyle}>
          <li>
            You may opt-out of receiving marketing and promotional materials
            from us by following the instructions provided in those materials.
          </li>
          <li>
            You may update or correct your personal information by logging into
            your account and making the necessary changes.
          </li>
          <li>
            You may request that we delete your personal information by
            contacting us.
          </li>
        </ul>

        <p>
          Please note that we may not be able to delete your personal
          information if we are required to retain it by law or for legitimate
          business purposes.
        </p>

        <h3>Changes to This Policy</h3>
        <p>
          We may update this privacy policy from time to time. If we make
          material changes to this policy, we will notify you by email or by
          posting a notice on our platform.
        </p>
      </div>

      <h2 style={subHeaderStyle}>Disclaimer of Liability</h2>
      <p>
        Hutupia is provided on an "as is" and "as available" basis, without any
        representations or warranties of any kind, express or implied. While we
        strive to provide a high-quality platform with accurate and reliable
        information, we cannot guarantee that Hutupia will be free from errors,
        defects, or interruptions.
      </p>
      <p>
        Hutupia shall have no liability for any interruptions in the use of
        Hutupia. This includes, but is not limited to, interruptions caused by
        maintenance, upgrades, or other technical issues, as well as
        interruptions caused by events outside of our control, such as natural
        disasters or acts of war.
      </p>
      <p>
        By using Hutupia, you acknowledge and accept the risks associated with
        using an online platform and agree to use Hutupia at your own risk. If
        you experience any issues or have any concerns about the use of Hutupia,
        please do not hesitate to contact us. We are committed to addressing any
        issues and improving the platform to provide the best possible
        experience for our users.
      </p>

      <h2 style={subHeaderStyle}>Termination</h2>
      <p>
        Hutupia reserves the right to terminate your access to the site at any
        time, without notice, and for any reason. If your account is terminated,
        you may lose access to any content, features, or services that you have
        purchased or created on the platform.
      </p>
      <p>
        We may also terminate your access to the site if we believe that you
        have violated our terms of use or engaged in any activity that we deem
        to be harmful or inappropriate.
      </p>
      <p>
        Before deleting your account, you must complete some important steps to
        ensure that deleting your account does not negatively affect the Hutupia
        community. These steps may include resolving any outstanding issues,
        terminating any contracts or subscriptions with other users, and
        deleting any owned projects. Please contact us if you have any questions
        or concerns about the termination process.
      </p>
      <p>
        If you have any questions or concerns about the termination of your
        account, please contact us. We are committed to resolving any issues and
        ensuring that our users have a positive experience on the platform.
      </p>

      <h2 style={subHeaderStyle}>Governing Law</h2>
      <p>
        These terms of use shall be governed by and interpreted in accordance
        with the laws of the jurisdiction in which Hutupia is headquartered. Any
        disputes arising out of or relating to these terms of use shall be
        subject to the exclusive jurisdiction of the courts in that
        jurisdiction.
      </p>
      <p>
        We strive to provide a transparent and fair platform for our users, and
        we are committed to addressing any issues or concerns that may arise. If
        you have any questions or concerns about these terms of use, please do
        not hesitate to contact us.
      </p>

      <h2 style={subHeaderStyle}>Changes to Terms</h2>
      <p>
        Hutupia reserves the right to update or modify these terms of use at any
        time, without notice. Your continued use of Hutupia after any such
        changes constitutes your acceptance of the new terms of use.
      </p>
    </div>
  )
}

export default EnglishTerms
