import axios from 'axios';

const getUserCountry = async () => {
    const primaryApiUrl = 'https://ipapi.co/json/';
    const backupApiUrl = 'http://ip-api.com/json/';

    try {
        // Try fetching data from primary API
        const response = await axios.get(primaryApiUrl);
        return response.data.country_code; // Adjust this based on the response structure of 'ipapi.co'
    } catch (error) {
        console.error('Error fetching user country from primary API:', error);
        try {
            // If primary API fails, attempt to fetch data from backup API
            const response = await axios.get(backupApiUrl);
            return response.data.countryCode; // Assuming 'countryCode' is correct based on your example
        } catch (error) {
            console.error('Error fetching user country from backup API:', error);
            return 'US'; // Default to US if both APIs fail
        }
    }
};

export default getUserCountry;
