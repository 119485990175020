import { t } from 'i18next'
import React from 'react'
import {
  AiOutlineTeam,
  AiOutlineNotification,
  AiOutlineContainer,
  AiOutlineUsergroupAdd,
  AiOutlineQuestionCircle,
  AiOutlineGlobal,
  AiOutlineBulb,
  AiOutlineShop,
  AiOutlineDollarCircle,
  AiOutlineShareAlt,
  AiOutlineLineChart,
  AiOutlineWarning,
  AiOutlineFlag,
  AiOutlineApartment,
  AiOutlineBank,
  AiOutlineUser,
} from 'react-icons/ai'
import { MdDynamicFeed, MdOutlineProductionQuantityLimits } from "react-icons/md"
import { RiAdvertisementLine } from "react-icons/ri";

const styles = {
  icon: {
    fontSize: '40px',
    color: '#007bff',
  },
}


const CardsConfigs = () => {
  return [
    {
      title: t('Chat_System'),
      icon: <AiOutlineTeam style={styles.icon} />,
      description: t('Chat_System_def'),
    },
    {
      title: t('Notification_System'),
      icon: <AiOutlineNotification style={styles.icon} />,
      description: t('Notification_System_def'),
    },
    {
      title: t('Feeds_System'),
      icon: <MdDynamicFeed style={styles.icon} />,
      description: t("Feeds_System_def"),
    },
    {
      title: t('Channels_System'),
      icon: <AiOutlineContainer style={styles.icon} />,
      description:
        t('Channels_System_def'),
    },
    {
      title: t('Networking_System'),
      icon: <AiOutlineUsergroupAdd style={styles.icon} />,
      description: t('Networking_System_def'),
    },
    {
      title: t('Inquiries_System'),
      icon: <AiOutlineQuestionCircle style={styles.icon} />,
      description: t('Inquiries_System_def'),
    },
    {
      title: t('Global_Inquiries_System'),
      icon: <AiOutlineGlobal style={styles.icon} />,
      description: t('Global_Inquiries_System_def'),
    },
    {
      title: t('Ideas_System'),
      icon: <AiOutlineBulb style={styles.icon} />,
      description: t('Ideas_System_def'),
    },
    {
      title: t('Products_Management_System'),
      icon: <AiOutlineShop style={styles.icon} />,
      description:t("Products_Management_System_def"),
    },
    {
      title: t('Investment_Packages'),
      icon: <AiOutlineDollarCircle style={styles.icon} />,
      description:t("Investment_Packages_def"),
    },
    {
      title: t('Products_Development_System'),
      icon: <MdOutlineProductionQuantityLimits style={styles.icon} />,
      description:t('Products_Development_System_def'),
    },
    {
      title: t('Resource_Sharing_System'),
      icon: <AiOutlineShareAlt style={styles.icon} />,
      description: t('Resource_Sharing_System_def'),
    },
    {
      title: t('Shares_Management'),
      icon: <AiOutlineLineChart style={styles.icon} />,
      description: t("Shares_Management_def"),
    },
    {
      title: t('Advertising_System'),
      icon: <RiAdvertisementLine style={styles.icon} />,
      description: t("Advertising_System_def"),
    },
    {
      title: t('Issues_System'),
      icon: <AiOutlineWarning style={styles.icon} />,
      description: t("Issues_System_def"),
    },
    {
      title: t('Campaigns_System'),
      icon: <AiOutlineFlag style={styles.icon} />,
      description: t("Campaigns_System_def"),
    },
    {
      title: t('Discussion_Rooms'),
      icon: <AiOutlineApartment style={styles.icon} />,
      description: t('Discussion_Rooms_def'),
    },
    {
      title: t('Portfolios'),
      icon: <AiOutlineBank style={styles.icon} />,
      description: t('Portfolios_def'),
    },
    {
      title: t('Contributors'),
      icon: <AiOutlineUser style={styles.icon} />,
      description: t('Contributors_def'),
    },
    {
      title: t('Followers_and_Followings'),
      icon: <AiOutlineUsergroupAdd style={styles.icon} />,
      description: t('Followers_and_Followings_def'),
    },
  ]
}

export default CardsConfigs
