import i18next from 'i18next'
import cookies from 'js-cookie'
import { useEffect } from 'react'

const PageDirection = () => {
    const lang = i18next.language
    const cookieLang = cookies.get('i18next')

    useEffect(() => {
        if (
            cookieLang === 'ar' ||
            lang === 'ar'
        ) {
            document.body.dir = 'rtl'
        }

    }, [cookieLang, lang, ])
}

export default PageDirection