import React from 'react'
import Slide from '@mui/material/Slide'

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  subtitle: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  description: {
    fontSize: '18px',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
  link: {
    display: 'block',
    margin: '10px 0',
    textDecoration: 'none',
    color: '#000',
    fontWeight: 'bold',
  },
  listContainer: {
    marginBottom: '40px',
  },
  listItem: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  listItemTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  listItemDescription: {
    fontSize: '16px',
    lineHeight: '1.5',
  },
  button: {
    margin: '20px 0',
  },
  icon: {
    fontSize: '40px',
    color: '#007bff',
  },
  featureTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#343a40',
  },
  featureDescription: {
    fontSize: '16px',
    lineHeight: '1.5',
    color: '#495057',
  },
  featureCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
    margin: '10px',
  },
}

const FeatureCard = ({ feature, index }) => {
  return (
    <Slide direction='up' in={true} timeout={index * 200}>
      <div style={styles.featureCard}>
        {feature.icon}
        <h3 style={styles.featureTitle}>{feature.title}</h3>
        <p style={styles.featureDescription}>{feature.description}</p>
      </div>
    </Slide>
  )
}

export default FeatureCard
