import React from 'react'
import ArabicTerms from './HutupiaTermsComps/ArabicTerms'
import EnglishTerms from './HutupiaTermsComps/EnglishTerms'
import i18next from 'i18next'

const HutupiaTerms = () => {
  const lang = i18next.language
  return lang === 'ar' ? <ArabicTerms /> : <EnglishTerms />
}

export default HutupiaTerms
